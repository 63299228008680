@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);
html{
  scroll-behavior: smooth;
}
body {
  font-family: 'Poppins' !important;
  background: url(/static/media/gogBackground.73e26962.jpg) !important;

}

.wallet-adapter-button {
  background-color: rgb(64, 65, 68);
  border: none;
  border-radius: 6px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Inter, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0px 20px;
}

.wallet-adapter-button-trigger {
  background-color: rgb(78, 68, 206);
}

.twoseven-ext-tab-media-modal {
  display: none;
  position: fixed;
  z-index: 2147483638;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.twoseven-ext-tab-media-modal-content {
  background-color: rgb(254, 254, 254);
  margin: 100px auto auto;
  padding: 20px;
  border: 1px solid rgb(136, 136, 136);
  height: 75%;
  max-width: 60%;
  min-width: 560px;
}

.twoseven-ext-tab-media-modal-content .close {
  position: relative;
  float: right;
  font-size: 28px;
  font-weight: bold;
  color: rgb(170, 170, 170) !important;
}

.twoseven-ext-tab-media-modal-content .close:hover,
.twoseven-ext-tab-media-modal-content .close:focus {
  text-decoration: none;
  cursor: pointer;
  color: rgb(0, 0, 0) !important;
}

.wallet-adapter-button {
  background-color: rgb(64, 65, 68);
  border: none;
  border-radius: 6px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Inter, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0px 20px;
}

.wallet-adapter-button-trigger {
  background-color: rgb(78, 68, 206);
}

.twoseven-ext-tab-media-modal {
  display: none;
  position: fixed;
  z-index: 2147483638;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.twoseven-ext-tab-media-modal-content {
  background-color: rgb(254, 254, 254);
  margin: 100px auto auto;
  padding: 20px;
  border: 1px solid rgb(136, 136, 136);
  height: 75%;
  max-width: 60%;
  min-width: 560px;
}

.twoseven-ext-tab-media-modal-content .close {
  position: relative;
  float: right;
  font-size: 28px;
  font-weight: bold;
  color: rgb(170, 170, 170) !important;
}

.twoseven-ext-tab-media-modal-content .close:hover,
.twoseven-ext-tab-media-modal-content .close:focus {
  text-decoration: none;
  cursor: pointer;
  color: rgb(0, 0, 0) !important;
}

* {
  padding: 0px;
  margin: 0px;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  background: rgb(255, 255, 255);
}

.styles_page__1f-kE {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer,
header {
  padding: 0px 74px;
  /* background: rgb(255, 255, 255); */
}

.styles_header__1GCLu {
  box-sizing: border-box;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  /* background: rgb(255, 255, 255); */
  z-index: 10;
}

.styles_page__1f-kE>* {
  max-width: 1280px;
}

.styles_side_swipe__1hE_v {
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgb(255, 255, 255);
  height: 100vh;
  width: 100%;
  transition: all 0.3s linear 0s;
  margin-left: -100%;
}

.styles_unordered__3CR8E {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.styles_side_swipe__1hE_v ul {
  flex-direction: column;
  align-items: flex-start;
  height: 50%;
  margin-top: 50px;
}

a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: rgb(155, 153, 143);
  text-decoration: none;
}

.styles_link_active__2p7bx {
  color: #4e44ce;
}

.styles_side_swipe__1hE_v ul li a {
  padding: 20px 30px;
  font-size: 2rem;
}

.styles_cross__2sUuB {
  position: absolute;
  right: 30px;
  top: 30px;
}

.styles_hamburger__bj5l7 {
  display: none;
}

.styles_logo__2tzDX {
  width: 183px;
  height: 35px;
}

.styles_nav__1YM61 {
  width: 438px;
}

section {
  padding: 50px 74px;
  max-width: 100vw;
  box-sizing: border-box;
  /* background: rgb(255, 255, 255); */
}

.styles_section__1sL__ {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-image: url('./images/backgroundtest.jpg'); */
}

.styles_subsection_content__3QiFY {
  width: 45%;
}

.styles_subsection_content__3QiFY>* {
  margin-bottom: 35px;
}

.styles_subheading__2q9qu {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: rgb(0, 0, 0);
  margin-bottom: 4px;
}

.styles_subheading_highlight__1Ezlb {
  font-weight: 800;
}

.styles_heading__32sCL {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 52px;
  color: rgb(49, 49, 48);
  font-family: poppins !important;
}

.styles_para__IZzIB {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: rgb(37, 43, 53);
  text-align: justify;
}

.styles_button_primary__3OLD_ {
  background: rgb(49, 49, 48);
  color: rgb(244, 241, 234);
  font-size: 18px;
  border: none;
  font-family: poppins !important;
  border-radius: 5px !important;
}

.styles_button_primary__3OLD_,
.styles_button_secondary__u1PRc {
  padding: 8px 26px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.styles_main_image__1s8Ca {
  width: 362px;
  height: 362px;
}

/* .styles_main_image__1s8Ca:hover {
  width: 362px;
  height: 362px;
  cursor: pointer;
  z-index: 400;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  box-shadow: 1px 1px 64px 0 #40bf95, 1px 1px 32px 0 #4080bf;
}

.styles_main_image__1s8Ca:hover {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
} */

.styles_section__1y3T- {
  width: 100%;
}

.styles_section__1y3T->div {
  margin-bottom: 70px;
}

.styles_flex_row__ysmct {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1em;
  color: rgb(0, 0, 0);
}

.styles_carousal__1_LFI {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.styles_leftarrow__3Qlf_,
.styles_rightarrow__1o6BQ {
  top: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.styles_leftarrow__3Qlf_ {
  left: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.styles_carouselContainer__22Nm5 {
  display: flex;
  flex-direction: row;
  width: 70%;
  overflow-x: auto;
  transition: all 0.3s linear 0s;
}

.styles_carouselContainer__22Nm5>* {
  min-width: 100%;
}

.styles_leftShift1__1MCjj> :first-child {
  margin-left: 0px;
}

.styles_imageCard__2ZS1N {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 256px;
  transition: all 0.3s linear 0s;
}

.styles_main_image_container__U30uf {
  padding: 16px;

}

.styles_imageCard__2ZS1N>.styles_main_image_container__U30uf>.styles_main_image__3WIys {
  width: 164px;
  height: 164px;
  object-fit: contain;
}

.styles_text__3RGm9 {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin: 0px;
  color: rgb(49, 49, 48);
}

.styles_rightarrow__1o6BQ {
  right: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.styles_rightarrow__1o6BQ>img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.styles_section_footer__1QSA9 {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: row;
  padding: 26px 0px;
}

.styles_section_footer_block__3diy_ {
  text-align: center;
  flex: 1 1;
}

.styles_section_footer_block__3diy_:not(:last-child) {
  border-right: 1px solid rgb(184, 184, 184);
}

.styles_section__1DwYq {
  opacity: 1;
  padding: 10px;
  margin: 100px auto;
  transition: all ease-in-out 0.3s;
}

/* .styles_section__1DwYq:hover {
  background-color: rgba(85, 64, 190, 0.25);
  transform: translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  border-radius: 15px;
  border: 4px solid rgba(85, 64, 190, 0.498);
} */

.styles_subssection_with_image__3qUd4 {}

.styles_subssection_with_image__3qUd4>img {
  position: absolute;
  bottom: 0px;
  width: 403px;
}

.styles_subssection_with_image__3qUd4 img:last-child {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.styles_subssection__3qEyy {
  -webkit-animation-name: styles_example__3nt39;
          animation-name: styles_example__3nt39;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.styles_subssection__3qEyy {
  width: 50%;
}

.styles_subssection__3qEyy h3 {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 75px;
  margin-bottom: 24px;
  color: rgb(239, 239, 239);
}

.styles_subssection__3qEyy p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: rgb(255, 255, 255);
}

.styles_section__1-7Mp {
  border-bottom: 1px solid rgba(49, 49, 48, 0.3);
}

.styles_heading__1kCwn {
  font-family: 'Playfair Display';
  color: rgb(49, 49, 48);
  font-size: 48px;
  line-height: 75px;
  font-weight: 400;
  margin-bottom: 40px;
}

.styles_roadmap__content_container__3afc7 {
  position: relative;
}

.styles_roadmap__timeline_bar__3OGAz {
  position: absolute;
  width: 1px;
  background: rgb(183, 183, 183);
  top: 0px;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.styles_roadmap__timeline_bar__scroller__23d3u {
  position: absolute;
  width: 12px;
  height: 165px;
  background: rgb(49, 49, 48);
  top: 0px;
  right: 0px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  transition: all 0.1s ease-in-out 0s;
}

.styles_roadmap__content_block__2RfSP {
  padding-top: 12px;
  padding-bottom: 40px;
  margin: auto;
  width: 35%;
}

.styles_roadmap__content_block__2RfSP img {
  background: #ffa42a;
  padding: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 10px #aad4e7;
}

.styles_roadmap__content_block__2RfSP::after {
  content: '';
  position: absolute;
  width: 26px;
  height: 1px;
  background: rgb(183, 183, 183);
  top: 0px;
}

.styles_roadmap__content_block_title__2pt_D {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1em;
  color: rgb(0, 0, 0);
}

.styles_roadmap__content_block_body__3cP9Z {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: rgb(37, 43, 53);
  opacity: 0.5;
}

.styles_icon_tray__2fJEO>* {
  margin-left: 16px;
}

.styles_icon_tray__2fJEO> :first-child {
  margin-left: 0px;
}

.styles_roadmap__content_block_body__list__fyJRH {
  display: block;
}

.styles_roadmap__content_block_body__list__fyJRH>li {
  position: relative;
}

.styles_roadmap__content_block_body__list__fyJRH>li::before {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  left: -18px;
  top: 12px;
  background: rgb(235, 232, 232);
}

.styles_blog_preview_row__151AK {
  margin-bottom: 38px;
}

.styles_blog_preview_images__2FNdR,
.styles_blog_preview_images_grid__2qVOv {
  width: 100%;
  margin-bottom: 16px;
}

.styles_blog_preview_images_grid__2qVOv {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 9px;
  gap: 9px;
}

.styles_blog_preview_images_grid__2qVOv>img {
  width: 100%;
  object-fit: contain;
}

.styles_blog_preview__3EkcE>* {
  margin-bottom: 28px;
}

.styles_blog_preview_title__1S5kC {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}

.styles_blog_preview_body_para__1X0MV {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: justify;
  color: rgb(37, 43, 53);
  opacity: 0.5;
}

.styles_blog_preview_link__IvACA {
  padding: 8px 26px;
  color: rgb(49, 49, 48);
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  border: 1px solid rgb(0, 0, 0);
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
}

.styles_blog_preview_row__151AK:last-child {
  margin-bottom: 0px;
}

.styles_blog_preview_images__2FNdR {
  display: inline-flex;
  justify-content: space-between;
}

.styles_blog_preview_images__2FNdR>img {
  object-fit: contain;
  width: 30%;
}

.styles_section__3bnkF {
  background: rgb(17, 15, 15);
  padding: 77px 74px 121px;
}

.styles_main_image__2ZuUF {
  display: none;
}

.styles_block__3PQBf {
  display: inline-flex;
  justify-content: space-between;
  color: rgb(255, 255, 255);
  position: relative;
  margin-bottom: 70px;
}

.styles_block__3PQBf:first-child {
  margin-top: 30px;
}

.styles_image_container__3Fnjy {
  width: 60px;
  height: 60px;
  border: 1px solid rgb(255, 255, 255);
  position: relative;
}

.styles_image_container__3Fnjy>img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.styles_bar__1s0xM {
  position: absolute;
  background: rgb(255, 255, 255);
  height: 200%;
  width: 2px;
  left: 23%;
}

.styles_bar__1s0xM::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
  background: rgb(255, 255, 255);
}

.styles_block_text__pEQQd {
  width: 70%;
}

.styles_block_text__pEQQd h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  margin-bottom: 10px;
}

.styles_block_text__pEQQd p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.styles_block__3PQBf:last-child {
  margin-bottom: 0px;
}

.styles_bar_last__3TfZM {
  max-height: 100%;
}

.styles_qna_container__1qSy8 {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 65px 4%;
  gap: 65px 4%;
}

.styles_qna__34Pwe {
  background: rgb(248, 248, 248);
  padding: 20px 16px;
}

.styles_question__1GGDP {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgb(0, 0, 0);
}

.styles_answer__KlbGe,
.styles_answer__KlbGe>a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}

.styles_footer__2gyfK {
  height: 300px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid rgba(49, 49, 48, 0.3);
}

.styles_footer__row_flex__2Z04u {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_icon_tray__3iPL->* {
  margin-right: 18px;
}

.styles_icon_tray__3iPL-> :last-child {
  margin-right: 0px;
}

.styles_footer__row_center__2MaEF {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgb(119, 128, 139);
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 1100px) {
  .styles_header__1GCLu {
    height: 70px;
    padding: 16px;
  }
}

@media screen and (min-width: 1100px) {
  .styles_cross__2sUuB {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .styles_logopair__23DMQ {
    display: inline-flex;
    align-items: center;
  }
}

@media screen and (max-width: 1100px) {
  .styles_hamburger__bj5l7 {
    display: block;
  }
}

@media screen and (max-width: 1100px) {
  .styles_logopair__23DMQ .styles_hamburger__bj5l7 {
    margin-right: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_logo__2tzDX {
    width: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .styles_menu__2rnm9 {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1100px) {
  .styles_cross__2sUuB {
    display: none;
  }
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 900px) {
  .styles_section__1sL__ {
    height: auto;
    flex-direction: column;
    margin-top: 100px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_subsection_content__3QiFY {
    width: 55%;
  }
}

@media screen and (max-width: 1100px) {
  .styles_subsection_content__3QiFY {
    width: 55%;
  }
}

@media screen and (max-width: 900px) {
  .styles_subsection_content__3QiFY {
    width: 100%;
    order: 2;
  }
}

@media screen and (max-width: 900px) {
  .styles_subheading__2q9qu {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: rgb(0, 0, 0);
  }
}

@media screen and (max-width: 900px) {
  .styles_subsection_content__3QiFY>* {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 900px) {
  .styles_heading__32sCL {
    font-size: 28px;
    line-height: 37px;
  }
}

@media screen and (max-width: 900px) {
  .styles_subsection_content__3QiFY>* {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 900px) {
  .styles_para__IZzIB {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 900px) {
  .styles_subsection_content__3QiFY>* {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 800px) {
  .styles_button_primary__3OLD_ {
    padding: 5px 18px;
    font-size: 12px;
    line-height: 21px;
  }
}

@media screen and (max-width: 900px) {
  .styles_subsection_content__3QiFY>* {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 900px) {
  .styles_subsection_image__UFkym {
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 900px) {
  .styles_subsection_image__UFkym>img {
    height: 200px;
    width: 200px;
  }
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carousal__1_LFI {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1150px) {

  .styles_leftarrow__3Qlf_,
  .styles_rightarrow__1o6BQ {
    display: none;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5 {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {

  .styles_leftarrow__3Qlf_,
  .styles_rightarrow__1o6BQ {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .styles_section_footer_display__1dnle {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .styles_section_footer_block_display__1Ues5 {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .styles_section_footer_block_display__1Ues5 {
    display: none;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carousal__1_LFI {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1150px) {

  .styles_leftarrow__3Qlf_,
  .styles_rightarrow__1o6BQ {
    display: none;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5 {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {
  .styles_carouselContainer__22Nm5>* {
    min-width: 10px;
  }
}

@media screen and (min-width: 1150px) {

  .styles_leftarrow__3Qlf_,
  .styles_rightarrow__1o6BQ {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .styles_section_footer_block_display__1Ues5 {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .styles_section_footer_block_display__1Ues5 {
    display: none;
  }
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 900px) {
  .styles_section__1DwYq {
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .styles_subssection_with_image__3qUd4 {
    text-align: center;
    height: 300px;
    margin-bottom: 24px;
    width: 100%;
    border-bottom: 1px solid rgba(196, 196, 196, 0.4);
  }
}

@media screen and (max-width: 900px) {
  .styles_subssection_with_image__3qUd4 img {
    object-fit: contain;
    height: 300px;
    position: inherit;
    padding: 0px;
  }
}

@media screen and (min-width: 900px) {
  .styles_subssection_with_image__3qUd4 img:last-child {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
}

@media screen and (max-width: 900px) {
  .styles_subssection_with_image__3qUd4 img {
    object-fit: contain;
    height: 300px;
    position: inherit;
    padding: 0px;
  }
}

@media screen and (max-width: 900px) {
  .styles_subssection_with_image__3qUd4 img:last-child {
    -webkit-transform: translateY(-101%);
            transform: translateY(-101%);
  }
}

@media screen and (max-width: 1100px) {
  .styles_subssection__3qEyy {
    width: 40%;
  }
}

@media screen and (max-width: 600px) {
  .styles_subssection__3qEyy {
    width: 40%;
  }
}

@media screen and (max-width: 900px) {
  .styles_subssection__3qEyy {
    width: 87%;
  }
}

@media screen and (max-width: 900px) {
  .styles_subssection__3qEyy h3 {
    font-size: 36.5714px;
    line-height: 43px;
  }
}

@media screen and (max-width: 900px) {
  .styles_subssection__3qEyy p {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 900px) {
  .styles_heading__1kCwn {
    font-size: 28px;
    line-height: 37px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__timeline_bar__3OGAz {
    left: 20px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__timeline_bar__scroller__23d3u {
    width: 9.5px;
    height: 130.69px;
  }
}

@media screen and (min-width: 900px) {
  .styles_roadmap__content_block__2RfSP:nth-child(2n) {
    text-align: left;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    padding-left: 50px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block__2RfSP {
    width: auto;
    padding-right: 50px;
    -webkit-transform: none;
            transform: none;
    position: relative;
    left: 20px;
    padding-left: 39px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_title__2pt_D {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_body__3cP9Z {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (min-width: 900px) {
  .styles_roadmap__content_block__2RfSP:nth-child(2n + 1) {
    text-align: right;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding-right: 50px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block__2RfSP {
    width: auto;
    padding-right: 50px;
    -webkit-transform: none;
            transform: none;
    position: relative;
    left: 20px;
    padding-left: 39px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_title__2pt_D {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_body__3cP9Z {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (min-width: 900px) {
  .styles_roadmap__content_block__2RfSP:nth-child(2n) {
    text-align: left;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    padding-left: 50px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block__2RfSP {
    width: auto;
    padding-right: 50px;
    -webkit-transform: none;
            transform: none;
    position: relative;
    left: 20px;
    padding-left: 39px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_title__2pt_D {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_body__3cP9Z {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (min-width: 900px) {
  .styles_roadmap__content_block__2RfSP:nth-child(2n + 1) {
    text-align: right;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding-right: 50px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block__2RfSP {
    width: auto;
    padding-right: 50px;
    -webkit-transform: none;
            transform: none;
    position: relative;
    left: 20px;
    padding-left: 39px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_title__2pt_D {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_body__3cP9Z {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (min-width: 900px) {
  .styles_roadmap__content_block__2RfSP:nth-child(2n) {
    text-align: left;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    padding-left: 50px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block__2RfSP {
    width: auto;
    padding-right: 50px;
    -webkit-transform: none;
            transform: none;
    position: relative;
    left: 20px;
    padding-left: 39px;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_title__2pt_D {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 900px) {
  .styles_roadmap__content_block_body__3cP9Z {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 900px) {
  .styles_heading__1kCwn {
    font-size: 28px;
    line-height: 37px;
  }
}

@media screen and (min-width: 900px) {
  .styles_blog_preview_row__151AK {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 900px) {
  .styles_blog_preview_row__151AK:nth-child(2n) {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 900px) {
  .styles_blog_preview_row__151AK:nth-child(2)>.styles_blog_preview_blog__1fQ3x {
    width: 95%;
    margin-right: 97px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview_title__1S5kC {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview__3EkcE>* {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview__3EkcE>* {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview_body_para__1X0MV {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview_link__IvACA {
    font-size: 12px;
    line-height: 21px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview__3EkcE>* {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 900px) {
  .styles_blog_preview_row__151AK {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 900px) {
  .styles_blog_preview_row__151AK:nth-child(3)>.styles_blog_preview_blog__1fQ3x {
    width: 40%;
    margin-left: 32px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview_title__1S5kC {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview__3EkcE>* {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview__3EkcE>* {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview_body_para__1X0MV {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview_link__IvACA {
    font-size: 12px;
    line-height: 21px;
  }
}

@media screen and (max-width: 900px) {
  .styles_blog_preview__3EkcE>* {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 480px) {
  .styles_section__3bnkF {
    padding: 77px 20px 121px;
  }
}

@media screen and (min-width: 900px) {
  .styles_section__3bnkF {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .styles_heading__1kCwn {
    font-size: 28px;
    line-height: 37px;
  }
}

@media screen and (min-width: 900px) {
  .styles_main_image__2ZuUF {
    display: block;
  }
}

@media screen and (min-width: 900px) {
  .styles_content_section__1zViy {
    width: 60%;
  }
}

@media screen and (min-width: 900px) {
  .styles_block_text__pEQQd h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
  }
}

@media screen and (min-width: 900px) {
  .styles_block_text__pEQQd p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgb(255, 255, 255);
    opacity: 0.5;
  }
}

@media screen and (min-width: 900px) {
  .styles_block_text__pEQQd h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
  }
}

@media screen and (min-width: 900px) {
  .styles_block_text__pEQQd p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgb(255, 255, 255);
    opacity: 0.5;
  }
}

@media screen and (min-width: 900px) {
  .styles_bar_last__3TfZM {
    height: 100%;
  }
}

@media screen and (min-width: 900px) {
  .styles_block_text__pEQQd h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
  }
}

@media screen and (min-width: 900px) {
  .styles_block_text__pEQQd p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgb(255, 255, 255);
    opacity: 0.5;
  }
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 900px) {
  .styles_heading__1kCwn {
    font-size: 28px;
    line-height: 37px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna_container__1qSy8 {
    grid-template-columns: auto;
    grid-row-gap: 36px;
    row-gap: 36px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna__34Pwe {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px;
  }
}

@media screen and (max-width: 900px) {
  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna__34Pwe {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px;
  }
}

@media screen and (max-width: 900px) {
  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna__34Pwe {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px;
  }
}

@media screen and (max-width: 900px) {
  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna__34Pwe {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px;
  }
}

@media screen and (max-width: 900px) {
  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna__34Pwe {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px;
  }
}

@media screen and (max-width: 900px) {
  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna__34Pwe {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px;
  }
}

@media screen and (max-width: 900px) {
  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna__34Pwe {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px;
  }
}

@media screen and (max-width: 900px) {
  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 900px) {
  .styles_qna__34Pwe {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px;
  }
}

@media screen and (max-width: 900px) {
  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 800px) {

  footer,
  header,
  section {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 900px) {
  footer {
    height: 300px;
  }
}

@media screen and (max-width: 900px) {
  .styles_footer__row_flex__2Z04u {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 900px) {
  .styles_footer__row_flex__2Z04u {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 900px) {
  .styles_logo__2tzDX {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1100px) {
  .styles_cross__2sUuB {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .styles_footer__row_flex__2Z04u {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 900px) {
  .styles_footer__row_flex__2Z04u {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 900px) {
  .styles_icon_tray__3iPL- {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
}

.twitter-button {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: #0d6efd;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: #0d6efd;
  border-color: #0d6efd;
  color: #fff;
  padding: 10px 50px;
}

.twitter-button:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.discord-button {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: #009688;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border-color: #009688;
  color: #fff;
  padding: 10px 50px;
  margin-left: 20px;
}

.discord-button:hover {
  color: #fff;
  background-color: #009688;
  border-color: #009688;
}

/* Second Section Of CSS */

.MuiSnackbar-root {
  left: 8px;
  right: 8px;
  display: flex;
  z-index: 1400;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.MuiSnackbar-anchorOriginTopCenter {
  top: 8px;
}

@media (min-width:600px) {
  .MuiSnackbar-anchorOriginTopCenter {
    top: 24px;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

.MuiSnackbar-anchorOriginBottomCenter {
  bottom: 8px;
}

@media (min-width:600px) {
  .MuiSnackbar-anchorOriginBottomCenter {
    left: 50%;
    right: auto;
    bottom: 24px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

.MuiSnackbar-anchorOriginTopRight {
  top: 8px;
  justify-content: flex-end;
}

@media (min-width:600px) {
  .MuiSnackbar-anchorOriginTopRight {
    top: 24px;
    left: auto;
    right: 24px;
  }
}

.MuiSnackbar-anchorOriginBottomRight {
  bottom: 8px;
  justify-content: flex-end;
}

@media (min-width:600px) {
  .MuiSnackbar-anchorOriginBottomRight {
    left: auto;
    right: 24px;
    bottom: 24px;
  }
}

.MuiSnackbar-anchorOriginTopLeft {
  top: 8px;
  justify-content: flex-start;
}

@media (min-width:600px) {
  .MuiSnackbar-anchorOriginTopLeft {
    top: 24px;
    left: 24px;
    right: auto;
  }
}

.MuiSnackbar-anchorOriginBottomLeft {
  bottom: 8px;
  justify-content: flex-start;
}

@media (min-width:600px) {
  .MuiSnackbar-anchorOriginBottomLeft {
    left: 24px;
    right: auto;
    bottom: 24px;
  }
}

.wallet-adapter-button {
  background-color: #404144;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Inter", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 20px
}

.wallet-adapter-button-trigger {
  background-color: #4e44ce
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: #fff
}

.wallet-adapter-button:not([disabled]):hover {
  background-image: linear-gradient(rgba(0, 0, 0, .15) 0 0)
}

.wallet-adapter-button[disabled] {
  background: #404144;
  color: #999;
  cursor: not-allowed
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-start-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px
}

.wallet-adapter-button-end-icon {
  margin-left: 8px
}

.wallet-adapter-button-start-icon {
  margin-right: 8px
}

.wallet-adapter-collapse {
  width: 100%
}

.wallet-adapter-dropdown {
  position: relative;
  display: inline-block
}

.wallet-adapter-dropdown-list {
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  background: #2c2d30;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, .6);
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, visibility .2s, -webkit-transform .2s ease;
  transition: opacity .2s ease, transform .2s ease, visibility .2s;
  transition: opacity .2s ease, transform .2s ease, visibility .2s, -webkit-transform .2s ease
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(10px);
          transform: translateY(10px)
}

.wallet-adapter-dropdown-list-item {
  background: #404144;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  height: 37px;
  color: #fff
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-image: linear-gradient(rgba(0, 0, 0, .15) 0 0)
}

.wallet-adapter-modal-collapse-button svg {
  align-self: center;
  fill: #999
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: -webkit-transform .15s ease-in;
  transition: transform .15s ease-in;
  transition: transform .15s ease-in, -webkit-transform .15s ease-in
}

.wallet-adapter-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .15s linear;
  background: rgba(0, 0, 0, .5);
  z-index: 1040;
  overflow-y: auto
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1
}

.wallet-adapter-modal-button-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 6px
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: #fff
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill .2s ease 0s
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff
}

.wallet-adapter-modal-logo {
  max-width: 100%
}

.wallet-adapter-modal-logo-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #141515;
  margin-bottom: 10px;
  padding: 12px
}

.wallet-adapter-modal-overlay {
  background: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.wallet-adapter-modal-container {
  display: flex;
  margin: 3rem;
  min-height: calc(100vh - 6rem);
  align-items: center;
  justify-content: center
}

@media (max-width:480px) {
  .wallet-adapter-modal-container {
    margin: 1rem;
    min-height: calc(100vh - 2rem)
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1050;
  max-width: 400px;
  border-radius: 10px;
  background: #2c2d30;
  box-shadow: 0 8px 20px rgba(0, 0, 0, .6);
  font-family: "Inter", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 40px 20px 20px;
  flex: 1 1
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%
}

.wallet-adapter-modal-wrapper-no-logo {
  padding-top: 30px
}

.wallet-adapter-modal-title {
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  margin-bottom: 27px;
  margin-top: 0;
  width: 100%;
  text-align: center;
  color: #fff
}

@media (max-width:374px) {
  .wallet-adapter-modal-title {
    font-size: 26px
  }
}

.wallet-adapter-modal-list {
  margin: 0 0 12px;
  padding: 0;
  width: 100%;
  list-style: none
}

.wallet-adapter-modal-list li:not(:first-of-type) {
  margin-top: 12px
}

/*# sourceMappingURL=2.632a6bd9.chunk.css.map */

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDTbtXK-F2qC0usEw.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDYbtXK-F2qC0usEw.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDZbtXK-F2qC0usEw.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDXbtXK-F2qC0s.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(0, 0, 0)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace
}

footer,
header {
  padding: 0 74px;
  background: rgb(0, 0, 0)
}

section {
  padding: 50px 74px;
  max-width: 100vw;
  box-sizing: border-box;
  /* background: rgb(0, 0, 0) */
}

@media screen and (max-width:800px) {

  footer,
  header,
  section {
    padding: 34px 16px
  }
}

.App {
  text-align: center
}

.styles_header__1GCLu {
  box-sizing: border-box;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  background: #262c3a;
  z-index: 10;
  max-width: 100% !important;
  padding: 50px;
  background-color: #211f23;
  background-image: linear-gradient(45deg, #000001, #3e2800);
  border-bottom: solid 2px #372d1d;
}

.styles_icon__3ldVO {
  margin-left: 10px
}

.styles_hamburger__bj5l7 {
  display: none
}

@media screen and (max-width:1100px) {
  .styles_header__1GCLu {
    height: 70px;
    padding: 16px
  }

  .styles_menu__2rnm9 {
    display: none
  }

  .styles_hamburger__bj5l7 {
    display: block
  }

  .styles_logopair__23DMQ {
    display: inline-flex;
    align-items: center
  }

  .styles_logopair__23DMQ .styles_hamburger__bj5l7 {
    margin-right: 10px
  }
}

.styles_nav__1YM61 {
  width: 550px
}

.styles_unordered__3CR8E {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0
}

a {
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #9b998f;
  text-decoration: none
}

.styles_link_active__2p7bx {
  color: #e5ae08
}

.styles_side_swipe__1hE_v {
  position: fixed;
  top: 0;
  left: 0;
  /* background: #fff; */
  height: 100vh;
  width: 100%;
  transition: all .3s linear;
  margin-left: -100%
}

.styles_side_swipe__1hE_v ul {
  flex-direction: column;
  align-items: flex-start;
  height: 50%;
  margin-top: 50px
}

.styles_side_swipe__1hE_v ul li a {
  padding: 20px 30px;
  font-size: 2rem
}

.styles_open__3YsVN {
  margin-left: 0
}

.styles_cross__2sUuB {
  position: absolute;
  right: 30px;
  top: 30px
}

@media screen and (min-width:1100px) {
  .styles_cross__2sUuB {
    display: none
  }
}

@media screen and (max-width:600px) {
  .styles_nav__1YM61 {
    width: 100%
  }

  .styles_nav__1YM61 a {
    font-size: 11.7626px;
    line-height: 24px
  }
}

.styles_logo__2tzDX {
  width: 183px;
  height: 35px
}

@media screen and (max-width:900px) {
  .styles_logo__2tzDX {
    width: 100px
  }
}

.styles_button_primary__3OLD_ {
  background: #ad7538;
  color: #ffffff;
  font-size: 18px;
  border: none;
  box-shadow: 0 6px 20px #896931;
}

.styles_button_primary__3OLD_,
.styles_button_secondary__u1PRc {
  padding: 8px 26px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-transform: capitalize
}

.styles_button_secondary__u1PRc {
  color: #313130;
  font-size: 16px;
  border: 1px solid #000;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  background: #fff
}

@media screen and (max-width:800px) {
  .styles_button_primary__3OLD_ {
    padding: 5px 18px;
    font-size: 12px;
    line-height: 21px
  }

  .styles_button_secondary__u1PRc {
    padding: 5px 18px;
    font-size: 10.6667px;
    line-height: 21px
  }
}

.styles_footer__2gyfK {
  height: 200px;
  max-width: 100% !important;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid rgba(49, 49, 48, .3);
  background: #211f23;
  background-image: linear-gradient(45deg, #000001, #3e2800);
  border-top: solid 2px #372d1d;
  position: relative;
}

.styles_footer__row_flex__2Z04u {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center
}

.styles_icon_tray__3iPL->* {
  margin-right: 18px
}

.styles_icon_tray__3iPL->:last-child {
  margin-right: 0
}

.styles_footer__row_center__2MaEF {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #77808b;
  margin-top: 50px;
}

@media screen and (max-width:900px) {
  footer {
    height: 300px
  }

  .styles_footer__row_flex__2Z04u {
    flex-direction: column;
    align-items: flex-start
  }

  .styles_icon_tray__3iPL- {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px
  }

  .styles_footer__row_flex__2Z04u {
    margin-bottom: 30px
  }
}

.styles_heading__1kCwn {
  font-family: "Playfair Display";
  color: #ffffff;
  font-size: 48px;
  line-height: 75px;
  font-weight: 400;
  margin-bottom: 40px
}

@media screen and (max-width:900px) {
  .styles_heading__1kCwn {
    font-size: 28px;
    line-height: 37px
  }
}

.styles_qna_container__1qSy8 {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 4%;
  grid-row-gap: 65px
}

.styles_qna__34Pwe {
  background: #000000;
  padding: 20px 16px
}

.styles_question__1GGDP {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgb(255, 255, 255)
}

.styles_answer__KlbGe,
.styles_answer__KlbGe>a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6)
}

@media screen and (max-width:900px) {
  .styles_qna_container__1qSy8 {
    grid-template-columns: auto;
    grid-row-gap: 36px
  }

  .styles_question__1GGDP {
    font-size: 12px;
    line-height: 28px
  }

  .styles_answer__KlbGe {
    font-size: 12px;
    line-height: 20px
  }

  .styles_qna__34Pwe {
    padding: 10px
  }
}

.styles_section__1-7Mp {
  border-bottom: none
}

.styles_roadmap__content_container__3afc7 {
  position: relative
}

.styles_roadmap__content_block__2RfSP {
  padding-top: 12px;
  padding-bottom: 40px;
  margin: auto;
  width: 35%;
  text-align: left !important;
}

.styles_roadmap__content_block_title__2pt_D {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: .1em;
  color: rgb(255, 255, 255)
}

.styles_roadmap__content_block_body__3cP9Z {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  opacity: 1
}

.styles_roadmap__content_block__2RfSP:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 1px;
  background: #b7b7b7;
  top: 0
}

.styles_roadmap__content_block_body__list__fyJRH {
  display: block
}

.styles_roadmap__content_block_body__list__fyJRH>li {
  position: relative
}

.styles_roadmap__content_block_body__list__fyJRH>li:before {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  left: -18px;
  top: 12px;
  background: #ebe8e8
}

.styles_icon_tray__2fJEO>* {
  margin-left: 16px
}

.styles_icon_tray__2fJEO>:first-child {
  margin-left: 0
}

@media screen and (min-width:900px) {
  .styles_roadmap__content_block__2RfSP:nth-child(odd) {
    text-align: right;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding-right: 50px
  }

  .styles_roadmap__content_block__2RfSP:nth-child(2n) {
    text-align: left;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    padding-left: 50px
  }

  .styles_roadmap__content_block__2RfSP:nth-child(odd):after {
    right: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%)
  }

  .styles_roadmap__content_block__2RfSP:nth-child(2n):after {
    left: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
  }
}

.styles_roadmap__timeline_bar__3OGAz {
  position: absolute;
  width: 1px;
  background: #b7b7b7;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%)
}

.styles_roadmap__timeline_bar__scroller__23d3u {
  position: absolute;
  width: 12px;
  height: 165px;
  background: #313130;
  top: 0;
  right: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  transition: all .1s ease-in-out
}

@media screen and (max-width:900px) {
  .styles_roadmap__content_block__2RfSP {
    width: auto;
    padding-right: 50px;
    -webkit-transform: none;
            transform: none;
    position: relative;
    left: 20px;
    padding-left: 39px
  }

  .styles_roadmap__content_block_title__2pt_D {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: .1em
  }

  .styles_roadmap__content_block_body__3cP9Z {
    font-size: 12px;
    line-height: 22px
  }

  .styles_roadmap__content_block_body__list__fyJRH>li:before {
    top: 8px
  }

  .styles_roadmap__timeline_bar__3OGAz {
    left: 20px
  }

  .styles_roadmap__content_block__2RfSP:after {
    left: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 20px
  }

  .styles_roadmap__timeline_bar__scroller__23d3u {
    width: 9.5px;
    height: 130.69px
  }
}

.styles_counters_container__3jATg {
  display: block;
  width: 100%;
  display: inline-flex;
  justify-content: space-between
}

.styles_text__ewKmq {
  width: 1005;
  font-family: poppins;
  font-size: 1rem;
  text-align: center
}

.styles_count__62t7K>span {
  display: inline-flex;
  font-family: poppins;
  font-size: 1.8rem;
  background: #2e2e2e;
  color: #fff;
  width: 50px;
  height: 80px;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  margin-right: 2px
}

@media screen and (max-width:1000px) {
  .styles_count__62t7K>span {
    display: inline-flex;
    font-family: poppins;
    font-size: 1.2rem;
    background: #2e2e2e;
    color: #fff;
    width: 40px;
    height: 65px;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    margin-right: 2px
  }
}

@media screen and (max-width:500px) {
  .styles_count__62t7K>span {
    display: inline-flex;
    font-family: poppins;
    font-size: 14px;
    background: #2e2e2e;
    color: #fff;
    width: 30px;
    height: 50px;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    margin-right: 2px
  }
}

.styles_section__1sL__ {
  height: auto !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10%;
  /* background-image: url('./images/backgroundtest.jpg'); */
}

.styles_subsection_content__3QiFY {
  width: 45%
}

.styles_subsection_content__3QiFY>* {
  margin-bottom: 35px
}

.styles_main_image__1s8Ca {
  width: 362px;
  height: 362px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(64, 128, 191, 0.5);
  transition: border-color 350ms ease, box-shadow 350ms;
  box-shadow: 0 0 0 20px rgb(14 157 168 / 0%), inset 0 0 5px #0c8a90, 0 1px 1px 1px rgb(255 255 255 / 40%);
  border-radius: 50px;
  -webkit-animation: loading 2s infinite ease-in-out;
  animation: loading 2s infinite ease-in-out;
}

.styles_subheading__2q9qu {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: .1em;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 4px
}

.styles_subheading_highlight__1Ezlb {
  font-weight: 800
}

.styles_heading__32sCL {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 52px;
  color: #ffffff
}

.styles_para__IZzIB {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  text-align: justify
}

@media screen and (min-width:1200px) {
  .styles_subsection_content__3QiFY {
    width: 55%
  }
}

@media screen and (max-width:1100px) {
  .styles_subsection_content__3QiFY {
    width: 55%
  }
}

@media screen and (max-width:900px) {
  .styles_section__1sL__ {
    height: auto;
    flex-direction: column;
    margin-top: 100px
  }

  .styles_subsection_content__3QiFY {
    width: 100%;
    order: 2
  }

  .styles_subsection_image__UFkym {
    margin-bottom: 56px
  }

  .styles_subsection_image__UFkym>img {
    height: 200px;
    width: 200px
  }

  .styles_subheading__2q9qu {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: .1em;
    color: #000
  }

  .styles_heading__32sCL {
    font-size: 28px;
    line-height: 37px
  }

  .styles_para__IZzIB {
    font-size: 12px;
    line-height: 18px
  }

  .styles_subsection_content__3QiFY>* {
    margin-bottom: 12px
  }
}

.styles_carousal__1_LFI {
  display: flex;
  align-items: center;
  justify-content: space-around
}

.styles_carouselContainer__22Nm5 {
  display: flex;
  flex-direction: row;
  width: 70%;
  overflow-x: auto;
  transition: all .3s linear
}

.styles_carouselContainer__22Nm5::-webkit-scrollbar {
  display: none
}

.styles_carouselContainer__22Nm5>* {
  min-width: 100%
}

.styles_leftShift1__1MCjj>:first-child {
  margin-left: 0
}

.styles_leftShift2__1xZ6G>:first-child {
  margin-left: -100%
}

.styles_leftShift3__3bVa6>:first-child {
  margin-left: -200%
}

.styles_leftShift4__34SxC>:first-child {
  margin-left: -300%
}

.styles_leftShift5__yHkCd>:first-child {
  margin-left: -400%
}

.styles_leftarrow__3Qlf_,
.styles_rightarrow__1o6BQ {
  top: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center
}

.styles_leftarrow__3Qlf_ {
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%)
}

.styles_rightarrow__1o6BQ {
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%)
}

.styles_rightarrow__1o6BQ>img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg)
}

@media screen and (min-width:1150px) {
  .styles_carousal__1_LFI {
    justify-content: space-between
  }

  .styles_carouselContainer__22Nm5 {
    width: 100%;
    justify-content: space-between
  }

  .styles_leftarrow__3Qlf_,
  .styles_rightarrow__1o6BQ {
    display: none
  }

  .styles_carouselContainer__22Nm5>* {
    min-width: 10px
  }
}

.styles_imageCard__2ZS1N {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 256px;
  transition: all .3s linear
}

.styles_main_image_container__U30uf {
  padding: 16px;

}

.styles_imageCard__2ZS1N>.styles_main_image_container__U30uf>.styles_main_image__3WIys {
  width: 164px;
  height: 164px;
  object-fit: contain
}

.styles_text__3RGm9 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin: 0;
  color: #ffffff
}

.styles_section__1y3T- {
  width: 100%;
  margin: 100px auto
}

.styles_section__1y3T->div {
  margin: 50px auto
}

.styles_flex_row__ysmct {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: .1em;
  color: #000
}

.styles_section_footer__1QSA9 {
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, .8);
  display: flex;
  flex-direction: row;
  padding: 26px 0
}

.styles_section_footer_block__3diy_ {
  text-align: center;
  flex: 1 1;
  color: white;
}

.styles_section_footer_block__3diy_:not(:last-child) {
  border-right: 1px solid #b8b8b8
}

@media screen and (min-width:800px) {
  .styles_section_footer_display__1dnle {
    display: none
  }
}

@media screen and (max-width:800px) {
  .styles_section_footer_block_display__1Ues5 {
    display: none
  }
}

.styles_section__1DwYq {
  /* background: #110f0f; */
  box-shadow: 0 .5px 0 rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 650px
}

.styles_subssection_with_image__3qUd4 {
  flex-grow: 100%
}

.styles_subssection__3qEyy {
  -webkit-animation-name: styles_example__3nt39;
          animation-name: styles_example__3nt39;
  -webkit-animation-duration: 1s;
          animation-duration: 1s
}

.styles_subssection_with_image__3qUd4>img {
  position: absolute;
  bottom: 0;
  width: 403px
}

@media screen and (min-width:900px) {
  .styles_subssection_with_image__3qUd4 img:last-child {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-animation-delay: .8s;
            animation-delay: .8s;

  }
}

.styles_subssection_with_image__3qUd4 img:last-child {
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
  margin-left: 2%;
}

.styles_subssection__3qEyy {
  width: 50%
}

.styles_subssection__3qEyy h3 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 75px;
  margin-bottom: 24px;
  color: #efefef
}

.styles_subssection__3qEyy p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #fff
}

@media screen and (max-width:1100px) {
  .styles_subssection__3qEyy {
    width: 40%
  }
}

@media screen and (max-width:600px) {
  .styles_subssection__3qEyy {
    width: 40%
  }
}

@media screen and (max-width:900px) {
  .styles_section__1DwYq {
    flex-direction: column;
    min-height: none
  }

  .styles_subssection__3qEyy {
    width: 87%
  }

  .styles_subssection_with_image__3qUd4 {
    text-align: center;
    height: 300px;
    margin-bottom: 24px;
    width: 100%;
    border-bottom: 1px solid hsla(0, 0%, 76.9%, .4)
  }

  .styles_subssection_with_image__3qUd4 img {
    object-fit: contain;
    height: 300px;
    position: inherit;
    padding: 0
  }

  .styles_subssection_with_image__3qUd4 img:last-child {
    -webkit-transform: translateY(-101%);
            transform: translateY(-101%);
    margin-top: 50%;
  }

  .styles_subssection__3qEyy h3 {
    font-size: 36.5714px;
    line-height: 43px
  }

  .styles_subssection__3qEyy p {
    font-size: 12px;
    line-height: 18px
  }
}

@-webkit-keyframes styles_toggle__J5QRO {
  0% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes styles_toggle__J5QRO {
  0% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.styles_blog_preview_row__151AK {
  margin-bottom: 38px
}

.styles_blog_preview_row__151AK:last-child {
  margin-bottom: 0
}

.styles_blog_preview_images__2FNdR,
.styles_blog_preview_images_grid__2qVOv {
  width: 100%;
  margin-bottom: 16px
}

.styles_blog_preview_images_grid__2qVOv {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 9px;
  grid-row-gap: 9px
}

.styles_blog_preview_images_grid__2qVOv>img {
  width: 100%;
  object-fit: contain
}

.styles_blog_preview_images__2FNdR {
  display: inline-flex;
  justify-content: space-between
}

.styles_blog_preview_images__2FNdR>img {
  object-fit: contain;
  width: 30%
}

@media screen and (min-width:900px) {
  .styles_blog_preview_row__151AK {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .styles_blog_preview_row__151AK:nth-child(2n) {
    flex-direction: row-reverse
  }

  .styles_blog_preview_row__151AK:nth-child(2)>.styles_blog_preview_blog__1fQ3x {
    width: 95%;
    margin-right: 97px
  }

  .styles_blog_preview_row__151AK:nth-child(3)>.styles_blog_preview_blog__1fQ3x {
    width: 40%;
    margin-left: 32px
  }
}

.styles_blog_preview__3EkcE>* {
  margin-bottom: 28px
}

.styles_blog_preview_title__1S5kC {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #000
}

.styles_blog_preview_body_para__1X0MV {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: justify;
  color: #252b35;
  opacity: .5
}

.styles_blog_preview_link__IvACA {
  padding: 8px 26px;
  color: #313130;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  border: 1px solid #000;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center
}

@media screen and (max-width:900px) {
  .styles_blog_preview_title__1S5kC {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #000
  }

  .styles_blog_preview_body_para__1X0MV {
    font-size: 12px;
    line-height: 22px
  }

  .styles_blog_preview_link__IvACA {
    font-size: 12px;
    line-height: 21px
  }

  .styles_blog_preview__3EkcE>* {
    margin-bottom: 15px
  }
}

.styles_page__1f-kE {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center
}

.styles_page__1f-kE>* {
  max-width: 1280px
}

.styles_section__3bnkF {
  background: #110f0f;
  padding: 77px 74px 121px
}

.styles_block__3PQBf {
  display: inline-flex;
  justify-content: space-between;
  color: #fff;
  position: relative;
  margin-bottom: 70px
}

.styles_block__3PQBf:first-child {
  margin-top: 30px
}

.styles_block__3PQBf:last-child {
  margin-bottom: 0
}

.styles_image_container__3Fnjy {
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  position: relative
}

.styles_image_container__3Fnjy>img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%)
}

.styles_block_text__pEQQd {
  width: 70%
}

.styles_block_text__pEQQd h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 10px
}

.styles_block_text__pEQQd p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #fff;
  opacity: .5
}

.styles_bar__1s0xM {
  position: absolute;
  background: #fff;
  height: 200%;
  width: 2px;
  left: 23%
}

.styles_bar_last__3TfZM {
  max-height: 100%
}

.styles_bar__1s0xM:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
  background: #fff
}

.styles_main_image__2ZuUF {
  display: none
}

@media screen and (max-width:480px) {
  .styles_section__3bnkF {
    padding: 77px 20px 121px
  }
}

@media screen and (min-width:900px) {
  .styles_section__3bnkF {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .styles_main_image__2ZuUF {
    display: block
  }

  .styles_content_section__1zViy {
    width: 60%
  }

  .styles_block_text__pEQQd h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #fff
  }

  .styles_block_text__pEQQd p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    opacity: .5
  }

  .styles_bar_last__3TfZM {
    height: 100%
  }
}

.letter {
  display: inline-block;
  color: #000;
  font-family: 'montserrat';
  font-size: 2vw;
  font-weight: bold;
  letter-spacing: 1vw;
  max-width: 700px;
  text-align: center;
  background: rgba(255, 253, 160, 1);
  background: -webkit-linear-gradient(left, #ffbb00 0%, #ecfb97 25%, #fb9797 50%, #00ffff 100%) repeat;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-animation-name: masked-animation;
  -webkit-animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

svg {
  font-family: 'Russo One', sans-serif;
  position: absolute;
  width: 30%;
  height: 100%;

}

svg .bottomSVG {
  font-family: 'Russo One', sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;

}

svg text {
  text-transform: uppercase;
  -webkit-animation: stroke 5s infinite alternate;
          animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #a03636;
  font-size: 140px;

}

.logoSVG {
  margin-left: -3%;
  margin-top: -3%;

}

.bottomSVG {
  margin-left: 67%;
}

@-webkit-keyframes stroke {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgb(160, 54, 54);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }

  70% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(54, 95, 160, 1);
  }

  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(54, 95, 160, 1);
    stroke-width: 3;
  }

  100% {
    fill: rgba(72, 138, 204, 1);
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

@keyframes stroke {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgb(160, 54, 54);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }

  70% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(54, 95, 160, 1);
  }

  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(54, 95, 160, 1);
    stroke-width: 3;
  }

  100% {
    fill: rgba(72, 138, 204, 1);
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.quantitySelector {
  color: white;
  font-family: poppins !important;
}

.quantityInput {
  padding: 15px;
  border-radius: 5px;
  border: none;
  background: rgba(37, 43, 56, 0.9);
  color: #fff;
}

.glow {
  margin-top: 100px;
  text-align: center;
}

.glowingText {
  color: #ffffff;
  text-shadow: 0 0 2px #ffffff, 0 0 10px #ffffff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca, 0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  -webkit-animation: blink 1.5s infinite alternate;
  animation: blink 1.5s infinite alternate;
}


@-webkit-keyframes blink {
  100% {
    text-shadow: 0 0 2px #ffffff, 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 40px #0ba9ca, 0 0 70px #0ba9ca, 0 0 80px #0ba9ca;
  }
}

@keyframes blink {
  100% {
    text-shadow: 0 0 2px #ffffff, 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 40px #0ba9ca, 0 0 70px #0ba9ca, 0 0 80px #0ba9ca;
  }
}

.styles_text__3RGm9 {
  font-family: 'poppins' !important;
}

h1, h2, h3, h4, h5, h6, p, span, label, a, button {
  font-family: 'poppins' !important;
}

@-webkit-keyframes loading {
  0% {
    box-shadow: 0 0 0 30px rgba(11, 121, 133, 0.45), 0 0 0 20px rgba(11, 121, 133, 0.65), 0 0 0 10px rgba(11, 121, 133, 0.85), inset 0 0 1px 1px rgba(0, 0, 0, 0.5), 0 0 5px 2px #02191B;
  }

  50% {
    box-shadow: none;
  }

  100% {
    box-shadow: 0 0 0 30px rgba(11, 121, 133, 0.45), 0 0 0 20px rgba(11, 121, 133, 0.65), 0 0 0 10px rgba(11, 121, 133, 0.85), inset 0 0 1px 1px rgba(0, 0, 0, 0.5), 0 0 5px 2px #02191B;
  }
}

@keyframes loading {
  0% {
    box-shadow: 0 0 0 30px rgba(11, 121, 133, 0.45), 0 0 0 20px rgba(11, 121, 133, 0.65), 0 0 0 10px rgba(11, 121, 133, 0.85), inset 0 0 1px 1px rgba(0, 0, 0, 0.5), 0 0 5px 2px #02191B;
  }

  50% {
    box-shadow: none;
  }

  100% {
    box-shadow: 0 0 0 30px rgba(11, 121, 133, 0.45), 0 0 0 20px rgba(11, 121, 133, 0.65), 0 0 0 10px rgba(11, 121, 133, 0.85), inset 0 0 1px 1px rgba(0, 0, 0, 0.5), 0 0 5px 2px #02191B;
  }
}

.teamCard {
  position: relative;
  margin: 0 auto;
  padding: 10px;
}

.teamCardImage {
  padding: 10px;
  margin-top: 25px;
  -webkit-transform: translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
          transform: translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  border-radius: 15px;
  border: 4px solid rgb(84 44 0);
  margin-bottom: 15px;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 5px #151515;
  transition: all ease-in-out 0.3s;
  background: linear-gradient(45deg, #000001, #3e2800);
}

.teamCardImage:hover {
  box-shadow: 0 0 5px #fff;
}

.teamImg {
  position: relative;
  width: 100%;
  height: 100%;
}

.teamMember {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.jobtile {
  font-size: 15px;
  color: #fff;
  padding-top: 10px;
  text-align: center;
}

.faq {
  margin-bottom: 150px;
  width: 100%;
}

.accordionContainer {
  margin: 0 auto;
}


.accordion {
  border: 0;
  border-radius: 2px;
}

.accordion__item {
  margin: 10px auto;
}


.accordion__button {
  color: #fff;
  cursor: pointer;
  padding: 30px 18px;
  width: 100%;
  text-align: left;
  border: none;
  background: linear-gradient(45deg, #000001, #3e2800);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.accordion__button:hover {
  background-color: #000;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  -webkit-animation: fadein 0.35s ease-in;
          animation: fadein 0.35s ease-in;
  background: #352200;
  color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.accordion__panel p {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.5rem;
  text-align: left;
  color: #fff;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  text-align: center;
}

.btn-7 {
    background: linear-gradient(0deg, #ffc107 0%, rgb(142 107 2) 100%);
    line-height: 42px;
    padding: 0;
    border: none;
  }
  .btn-7 span { 
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .btn-7:before,
  .btn-7:after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    background: rgba(251,75,2,1);
    box-shadow:
     -7px -7px 20px 0px rgba(255,255,255,.9),
     -4px -4px 5px 0px rgba(255,255,255,.9),
     7px 7px 20px 0px rgba(0,0,0,.2),
     4px 4px 5px 0px rgba(0,0,0,.3);
    transition: all 0.3s ease;
  }
  .btn-7:before{
     height: 0%;
     width: 2px;
  }
  .btn-7:after {
    width: 0%;
    height: 2px;
  }
  .btn-7:hover{
    color: rgba(251,75,2,1);
    background: transparent;
  }
  .btn-7:hover:before {
    height: 100%;
  }
  .btn-7:hover:after {
    width: 100%;
  }
  .btn-7 span:before,
  .btn-7 span:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: rgba(251,75,2,1);
    box-shadow:
     -7px -7px 20px 0px rgba(255,255,255,.9),
     -4px -4px 5px 0px rgba(255,255,255,.9),
     7px 7px 20px 0px rgba(0,0,0,.2),
     4px 4px 5px 0px rgba(0,0,0,.3);
    transition: all 0.3s ease;
  }
  .btn-7 span:before {
    width: 2px;
    height: 0%;
  }
  .btn-7 span:after {
    height: 2px;
    width: 0%;
  }
  .btn-7 span:hover:before {
    height: 100%;
  }
  .btn-7 span:hover:after {
    width: 100%;
  }
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  font-family: "Baloo 2";
}

html {
  background-color: "#4C4C4C";
}

div {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

:root {
  --yellow: #fccd35;
  --pink: #ff19e9;
  --green: #00cc00;
  --white: #ffffff;
  --light-grey: #b1b1b1;
  --dark-grey: #2b2b2b;
  --black: #000000;
}

